"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_redux_1 = require("react-redux");
var MuteSounds_1 = require("./MuteSounds");
var UnmuteSounds_1 = require("./UnmuteSounds");
function useSoundControls() {
    var muted = (0, react_redux_1.useSelector)(function (state) { return state.soundsMuted; });
    var dispatch = (0, react_redux_1.useDispatch)();
    return {
        mute: function () {
            dispatch({ type: MuteSounds_1.MUTE_SOUNDS });
            localStorage.setItem('soundsMuted', 'true');
        },
        unmute: function () {
            dispatch({ type: UnmuteSounds_1.UNMUTE_SOUNDS });
            localStorage.removeItem('soundsMuted');
        },
        muted: muted
    };
}
exports.default = useSoundControls;
