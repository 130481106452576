"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SpeechBubbleDirection;
(function (SpeechBubbleDirection) {
    SpeechBubbleDirection[SpeechBubbleDirection["TOP_LEFT"] = 0] = "TOP_LEFT";
    SpeechBubbleDirection[SpeechBubbleDirection["TOP_RIGHT"] = 1] = "TOP_RIGHT";
    SpeechBubbleDirection[SpeechBubbleDirection["BOTTOM_LEFT"] = 2] = "BOTTOM_LEFT";
    SpeechBubbleDirection[SpeechBubbleDirection["BOTTOM_RIGHT"] = 3] = "BOTTOM_RIGHT";
})(SpeechBubbleDirection || (SpeechBubbleDirection = {}));
exports.default = SpeechBubbleDirection;
