"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rules_api_1 = require("@gamepark/rules-api");
var MovePlayed_1 = require("../Actions/MovePlayed");
var MoveUndone_1 = require("../Actions/MoveUndone");
var EjectPlayer_1 = require("../EjectPlayer");
var GiveUp_1 = require("../GiveUp");
var PlayTutorialMoves_1 = require("../Tutorial/PlayTutorialMoves");
var ArrayUtil_1 = require("../util/ArrayUtil");
function gameMiddleware(api, RulesView) {
    return function (store) { return function (next) { return function (event) {
        switch (event.type) {
            case MovePlayed_1.MOVE_PLAYED:
                if (!event.local) {
                    api.play(event.move);
                }
                break;
            case MoveUndone_1.MOVE_UNDONE:
                var game = new RulesView(JSON.parse(JSON.stringify(store.getState().setup)));
                var actions = store.getState().actions;
                if (!actions)
                    return console.error('gameMiddleware - MOVE_UNDONE: Actions is missing');
                var index = (0, ArrayUtil_1.findLastIndex)(actions, function (action) { return action.id === event.actionId; });
                if (index === -1)
                    return console.error('gameMiddleware - MOVE_UNDONE: Action not found');
                var consecutiveActions = actions.slice(index + 1).filter(function (action) { return !action.cancelled; });
                (0, rules_api_1.replayActions)(game, actions.filter(function (action) { return !action.delayed && !action.cancelled; }));
                if (!(0, rules_api_1.hasUndo)(game) || !game.canUndo(actions[index], consecutiveActions))
                    return console.warn('gameMiddleware - MOVE_UNDONE: Undo forbidden');
                api.undo(event.actionId);
                break;
            case EjectPlayer_1.EJECT_PLAYER:
                api.eject(event.playerId);
                break;
            case GiveUp_1.GIVE_UP:
                api.giveUp();
                break;
            case PlayTutorialMoves_1.PLAY_TUTORIAL_MOVES:
                api.playTutorialMoves(event.quantity);
                break;
        }
        return next(event);
    }; }; };
}
exports.default = gameMiddleware;
