"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
var free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_i18next_1 = require("react-i18next");
var Menu_1 = require("../Menu");
function ContrastThemeButton(_a) {
    var theme = _a.theme;
    var t = (0, react_i18next_1.useTranslation)().t;
    return ((0, jsx_runtime_1.jsxs)("button", __assign({ css: Menu_1.menuButtonCss, onClick: theme.toggleContrast }, { children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: theme.light ? free_solid_svg_icons_1.faMoon : free_solid_svg_icons_1.faSun }), theme.light ? t('Dark mode') : t('Light mode')] })));
}
exports.default = ContrastThemeButton;
