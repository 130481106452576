"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isWithPlayerIdOptions(optionsSpec) {
    var playersOptions = optionsSpec.players;
    if (!playersOptions)
        return false;
    var idOptions = playersOptions.id;
    return !!idOptions;
}
exports.default = isWithPlayerIdOptions;
