"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
var react_1 = require("@emotion/react");
var react_i18next_1 = require("react-i18next");
var index_1 = require("../index");
var Menu_1 = require("../Menu");
function SignInToChat() {
    var t = (0, react_i18next_1.useTranslation)().t;
    var query = new URLSearchParams(window.location.search);
    var locale = query.get('locale') || 'en';
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ css: style }, { children: [(0, jsx_runtime_1.jsx)("p", __assign({ css: textCss }, { children: t('sign-in-to-chat') })), (0, jsx_runtime_1.jsx)("button", __assign({ css: [Menu_1.buttonCss, signInButtonCss], onClick: function () { return window.location.href = "".concat(index_1.PLATFORM_URI, "/").concat(locale, "/auth/sign-in?callbackUrl=").concat(encodeURIComponent(window.location.href)); } }, { children: t('Sign in') }))] })));
}
exports.default = SignInToChat;
var style = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  padding: 8px 16px;\n  text-align: center;\n"], ["\n  width: 100%;\n  padding: 8px 16px;\n  text-align: center;\n"])));
var textCss = (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  line-height: 1.5;\n  margin: 0;\n  font-weight: bold;\n"], ["\n  line-height: 1.5;\n  margin: 0;\n  font-weight: bold;\n"])));
var signInButtonCss = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0.2em 0.5em;\n  border-radius: 2em;\n  border: 0.05em solid #002448;\n  color: #002448;\n  background: transparent;\n  font-size: 1.25em;\n\n  &:focus, &:hover {\n    background: #c2ebf1;\n  }\n\n  &:active {\n    background: #ade4ec;\n  }\n"], ["\n  padding: 0.2em 0.5em;\n  border-radius: 2em;\n  border: 0.05em solid #002448;\n  color: #002448;\n  background: transparent;\n  font-size: 1.25em;\n\n  &:focus, &:hover {\n    background: #c2ebf1;\n  }\n\n  &:active {\n    background: #ade4ec;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
