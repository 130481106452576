"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function GamePointIcon(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({}, props, { viewBox: "210 150 420 300" }, { children: [(0, jsx_runtime_1.jsx)("path", { fill: "#002448", d: "M493.7,138.3v55.4h-79.1c0,0-28-5.6-28.5,39c-0.6,44.3,0,152.2,0,152.2H433l0.6-74.3h-20.1l-0.6-57.9h80.8\r\n\t\tv191.2H374.2c0,0-57.3-10.4-48.9-73.1l-0.8-159.6c0,0,8.2-68.9,78.8-75.4L493.7,138.3z" }), (0, jsx_runtime_1.jsx)("circle", { fill: "#002448", cx: "259.7", cy: "317.4", r: "20.7" }), (0, jsx_runtime_1.jsx)("circle", { fill: "#002448", cx: "547.8", cy: "317.4", r: "20.7" }), (0, jsx_runtime_1.jsx)("path", { fill: "#28B8CE", d: "M479.6,146.7V202h-79.1c0,0-28-5.6-28.5,39c-0.6,44.3,0,152.2,0,152.2h46.9l0.6-74.3h-20.1l-0.6-57.9h80.8\r\n\t\tv191.2H360.1c0,0-57.3-10.4-48.9-73.1l-0.8-159.6c0,0,8.2-68.9,78.8-75.4L479.6,146.7z" })] })));
}
exports.default = GamePointIcon;
