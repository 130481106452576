"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
var free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var GameContext_1 = require("../GameProvider/GameContext");
var Menu_1 = require("../Menu");
function RestartTutorialButton() {
    var _a;
    var t = (0, react_i18next_1.useTranslation)().t;
    var game = (_a = (0, react_1.useContext)(GameContext_1.gameContext)) === null || _a === void 0 ? void 0 : _a.game;
    if (!game)
        throw new Error('Cannot use TutorialButton outside a GameProvider context');
    var onClick = function () {
        localStorage.removeItem(game);
        window.location.reload();
    };
    return ((0, jsx_runtime_1.jsxs)("button", __assign({ css: Menu_1.menuButtonCss, onClick: onClick }, { children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faGraduationCap }), t('Restart the tutorial')] })));
}
exports.default = RestartTutorialButton;
