export const headerHeight = 7
export const margin = 1
export const mosquitoTokenSize = 10
export const boardSize = 100 - headerHeight - margin * 2
export const playerboardSize = (98 * 16 / 9 - boardSize) / 2
export const playerboardTokenDelta = 2.5
export const playerboardTokenBoarderMargin = (playerboardSize - 3 * mosquitoTokenSize - 2 * playerboardTokenDelta) / 2
export const playerBoardDelta = (100 * 16 / 9 - boardSize) / 2 + boardSize
export const goldenMosquitoPositionTop = 10
export const eatenMosquitoPostionTop = 25
export const goldenMosquitoPositionLeft = (playerboardSize - mosquitoTokenSize - 2) / 9
export const animalHeight = 20
export const animalRatio = 2480 / 3508
export const animalWidth = animalHeight * animalRatio
export const jungleSpaceDelta = 23.2
export const pondSpaceDelta = 23.6
export const playerColorBlue = '#3e7ad4'
export const playerColorOrange = '#eb6d41'
export const playerColorLoser = '#D3D3D3'
export const highlightColorGreen = '0, 130, 0'
export const highlightColorWhite = '255, 255, 255'