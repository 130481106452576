"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loopWithFuse = exports.applyAutomaticMoves = void 0;
var RandomMove_1 = require("./RandomMove");
function applyAutomaticMoves(game, preprocessMove) {
    var moves = [];
    loopWithFuse(function () {
        if (moves.length === 0 && game.getAutomaticMoves) {
            moves.push.apply(moves, (0, RandomMove_1.randomizeMoves)(game, game.getAutomaticMoves()));
        }
        var move = moves.shift();
        if (move) {
            if (preprocessMove)
                preprocessMove(move);
            game.play(JSON.parse(JSON.stringify(move)));
            return true;
        }
        else {
            return false;
        }
    }, { errorFn: function () { return new Error("Infinite loop detected while applying move consequences: ".concat(JSON.stringify(moves), ")")); } });
}
exports.applyAutomaticMoves = applyAutomaticMoves;
function loopWithFuse(repeat, options) {
    var attempts = (options === null || options === void 0 ? void 0 : options.attempts) || (process.env.NODE_ENV === 'development' ? 100 : 1000);
    var errorFn = (options === null || options === void 0 ? void 0 : options.errorFn) || (function () { return new Error('Infinite loop detected'); });
    while (repeat()) {
        if (attempts == 0) {
            throw errorFn();
        }
        attempts--;
    }
}
exports.loopWithFuse = loopWithFuse;
