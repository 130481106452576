"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var client_1 = require("@apollo/client");
var GET_ME = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["query Me { me { user { id name } } }"], ["query Me { me { user { id name } } }"])));
function useMe() {
    var _a;
    return (_a = (0, client_1.useQuery)(GET_ME, { ssr: false }).data) === null || _a === void 0 ? void 0 : _a.me;
}
exports.default = useMe;
var templateObject_1;
