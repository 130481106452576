"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAnimationsListener = void 0;
var AnimationBreakComplete_1 = require("../Actions/AnimationBreakComplete");
var AnimationComplete_1 = require("../Actions/AnimationComplete");
function createAnimationsListener(store) {
    var timers = new Map();
    return function () {
        var _a;
        var state = store.getState();
        (_a = state.actions) === null || _a === void 0 ? void 0 : _a.forEach(function (action) {
            var animation = action.animation;
            if (animation && !timers.has(animation.id)) {
                timers.set(animation.id, setTimeout(function () {
                    timers.delete(animation.id);
                    store.dispatch((0, AnimationComplete_1.animationComplete)(animation.id));
                }, animation.duration * 1000));
            }
        });
        if (state.animationsBreak && !timers.has(-1)) {
            timers.set(-1, setTimeout(function () {
                timers.delete(-1);
                store.dispatch(AnimationBreakComplete_1.animationBreakCompleteAction);
            }, 100));
        }
    };
}
exports.createAnimationsListener = createAnimationsListener;
