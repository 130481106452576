"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.dialogDefaultCss = void 0;
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
var react_1 = require("@emotion/react");
var react_2 = require("react");
var react_dom_1 = require("react-dom");
function Dialog(_a) {
    var children = _a.children, open = _a.open, backdropCss = _a.backdropCss, onBackdropClick = _a.onBackdropClick, _b = _a.transitionDelay, transitionDelay = _b === void 0 ? 0.3 : _b, _c = _a.rootId, rootId = _c === void 0 ? 'root' : _c, props = __rest(_a, ["children", "open", "backdropCss", "onBackdropClick", "transitionDelay", "rootId"]);
    var _d = (0, react_2.useState)(open), display = _d[0], setDisplay = _d[1];
    (0, react_2.useEffect)(function () {
        if (open) {
            setDisplay(true);
        }
        else {
            var timeout_1 = setTimeout(function () { return setDisplay(false); }, transitionDelay * 1000);
            return function () { return clearTimeout(timeout_1); };
        }
    }, [open, transitionDelay]);
    if (!display)
        return null;
    var root = document.getElementById(rootId);
    if (!root) {
        console.error('Dialog cannot be displayed because this element id does not exists:', rootId);
        return null;
    }
    return (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsx)("div", __assign({ css: [backdropStyle(transitionDelay), !open && hide(transitionDelay), backdropCss], onClick: onBackdropClick }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ onClick: function (event) { return event.stopPropagation(); } }, props, { children: children })) })), root);
}
exports.default = Dialog;
var fadeIn = (0, react_1.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n"], ["\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n"])));
var fadeOut = (0, react_1.keyframes)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n"], ["\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n"])));
var backdropStyle = function (transitionDelay) { return (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  width: 100vw;\n  height: 100vh;\n  background: rgba(0, 0, 0, 0.5);\n  z-index: 1100;\n  transition: opacity ", "s;\n  animation: ", " ", "s forwards;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  position: fixed;\n  top: 0;\n  width: 100vw;\n  height: 100vh;\n  background: rgba(0, 0, 0, 0.5);\n  z-index: 1100;\n  transition: opacity ", "s;\n  animation: ", " ", "s forwards;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), transitionDelay, fadeIn, transitionDelay); };
var hide = function (transitionDelay) { return (0, react_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  animation: ", " ", "s forwards;\n  pointer-events: none;\n"], ["\n  animation: ", " ", "s forwards;\n  pointer-events: none;\n"])), fadeOut, transitionDelay); };
exports.dialogDefaultCss = (0, react_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  background-color: #f0fbfc;\n  color: #002448;\n  font-size: 3.2em;\n  padding: 1em;\n  border-radius: 1em;\n  border: 0.2em solid #28B8CE;\n  box-shadow: 0 0 0.2em black;\n  font-family: \"Mulish\", sans-serif;\n"], ["\n  position: relative;\n  background-color: #f0fbfc;\n  color: #002448;\n  font-size: 3.2em;\n  padding: 1em;\n  border-radius: 1em;\n  border: 0.2em solid #28B8CE;\n  box-shadow: 0 0 0.2em black;\n  font-family: \"Mulish\", sans-serif;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
