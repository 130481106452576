"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
var react_1 = require("@emotion/react");
function Letterbox(_a) {
    var children = _a.children, _b = _a.width, width = _b === void 0 ? 16 : _b, _c = _a.height, height = _c === void 0 ? 9 : _c, _d = _a.left, left = _d === void 0 ? 50 : _d, _e = _a.top, top = _e === void 0 ? 50 : _e, props = __rest(_a, ["children", "width", "height", "left", "top"]);
    return (0, jsx_runtime_1.jsxs)("div", __assign({ css: [container(left, top), aspectRatio(width, height)] }, props, { children: [(0, jsx_runtime_1.jsx)("canvas", { css: [canvas, aspectRatio(width, height)], width: width, height: height }), (0, jsx_runtime_1.jsx)("div", __assign({ css: content }, { children: children }))] }));
}
exports.default = Letterbox;
var aspectRatio = function (width, height) { return (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @media (min-aspect-ratio: ", "/", ") {\n    height: 100%;\n  }\n  @media (max-aspect-ratio: ", "/", ") {\n    width: 100%;\n  }\n"], ["\n  @media (min-aspect-ratio: ", "/", ") {\n    height: 100%;\n  }\n  @media (max-aspect-ratio: ", "/", ") {\n    width: 100%;\n  }\n"])), width, height, width, height); };
var container = function (left, top) { return (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  display: inline-block;\n  left: ", "%;\n  top: ", "%;\n  transform: translate(-", "%, -", "%);\n"], ["\n  position: absolute;\n  display: inline-block;\n  left: ", "%;\n  top: ", "%;\n  transform: translate(-", "%, -", "%);\n"])), left, top, left, top); };
var canvas = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0;\n  margin: 0;\n"], ["\n  padding: 0;\n  margin: 0;\n"])));
var content = (0, react_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n"], ["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
