"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_redux_1 = require("react-redux");
function useNow(_a) {
    var _b = _a === void 0 ? { updateRate: 1000, standby: false } : _a, _c = _b.updateRate, updateRate = _c === void 0 ? 1000 : _c, _d = _b.standby, standby = _d === void 0 ? false : _d;
    var _e = (0, react_1.useState)((new Date()).getTime()), now = _e[0], setNow = _e[1];
    var clientTimeDelta = (0, react_redux_1.useSelector)(function (state) { return state.clientTimeDelta || 0; });
    (0, react_1.useEffect)(function () {
        if (!standby) {
            var interval_1 = setInterval(function () {
                setNow(function (now) { return now + updateRate; });
            }, updateRate);
            return function () { return clearInterval(interval_1); };
        }
        return;
    }, [standby, updateRate]);
    return now - clientTimeDelta;
}
exports.default = useNow;
