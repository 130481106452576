"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.REFUSE_REMATCH = exports.ACCEPT_REMATCH = exports.REMATCH = exports.CAN_REMATCH = void 0;
var client_1 = require("@apollo/client");
exports.CAN_REMATCH = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query Game($id: String!) {\n    game(id: $id) {\n      id canRematch rematch { id startDate players { name userId ready } }\n    }\n  }\n"], ["\n  query Game($id: String!) {\n    game(id: $id) {\n      id canRematch rematch { id startDate players { name userId ready } }\n    }\n  }\n"])));
exports.REMATCH = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation Rematch($gameId: String!) {\n    rematch(gameId: $gameId) { id canRematch rematch { id startDate myPlayerId players { id name userId ready } } }\n  }\n"], ["\n  mutation Rematch($gameId: String!) {\n    rematch(gameId: $gameId) { id canRematch rematch { id startDate myPlayerId players { id name userId ready } } }\n  }\n"])));
exports.ACCEPT_REMATCH = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation AcceptRematch($gameId: String!) {\n    joinGame(gameId: $gameId) { id myPlayerId players { id ready } }\n  }\n"], ["\n  mutation AcceptRematch($gameId: String!) {\n    joinGame(gameId: $gameId) { id myPlayerId players { id ready } }\n  }\n"])));
exports.REFUSE_REMATCH = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation RefuseRematch($gameId: String!) {\n    leaveGameSetup(gameId: $gameId) { id myPlayerId players { id ready } }\n  }\n"], ["\n  mutation RefuseRematch($gameId: String!) {\n    leaveGameSetup(gameId: $gameId) { id myPlayerId players { id ready } }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
