"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useKeyDown(key, callback) {
    (0, react_1.useEffect)(function () {
        function onKeydown(event) {
            if (event.key === key) {
                callback();
            }
        }
        document.addEventListener('keydown', onKeydown);
        return function () { return document.removeEventListener('keydown', onKeydown); };
    }, [key, callback]);
}
exports.default = useKeyDown;
