"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addStylesheetUrl = exports.menuDialogCss = exports.hide = exports.menuBaseCss = exports.menuButtonCss = exports.menuFloatingButtonCss = exports.floatingButtonCss = exports.buttonCss = exports.menuFontSize = void 0;
/** @jsxImportSource @emotion/react */
var react_1 = require("@emotion/react");
var react_components_1 = require("@gamepark/react-components");
exports.menuFontSize = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 4em;\n  @media only screen and (min-width: 960px) {\n    font-size: 3.2em;\n  }\n"], ["\n  font-size: 4em;\n  @media only screen and (min-width: 960px) {\n    font-size: 3.2em;\n  }\n"])));
exports.buttonCss = (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: none;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n  }\n"], ["\n  border: none;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n  }\n"])));
exports.floatingButtonCss = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  position: fixed;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-shadow: 0 0 0.2em black;\n  z-index: 990;\n"], ["\n  ", ";\n  ", ";\n  position: fixed;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-shadow: 0 0 0.2em black;\n  z-index: 990;\n"])), exports.menuFontSize, exports.buttonCss);
exports.menuFloatingButtonCss = (0, react_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  top: 0;\n  right: 0;\n  border-bottom-left-radius: 25%;\n"], ["\n  ", ";\n  top: 0;\n  right: 0;\n  border-bottom-left-radius: 25%;\n"])), exports.floatingButtonCss);
exports.menuButtonCss = (0, react_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  display: block;\n  font-size: 1.125em;\n  padding: 0.2em 0.5em;\n  border-radius: 2em;\n  border: 0.05em solid #002448;\n  margin-bottom: 0.5em;\n  color: #002448;\n  background: transparent;\n\n  &:focus, &:hover {\n    background: #c2ebf1;\n  }\n\n  &:active {\n    background: #ade4ec;\n  }\n\n  &:disabled {\n    color: #555555;\n    border-color: #555555;\n    background: transparent;\n    cursor: auto;\n  }\n\n  > svg {\n    margin-right: 0.5em;\n  }\n"], ["\n  ", ";\n  display: block;\n  font-size: 1.125em;\n  padding: 0.2em 0.5em;\n  border-radius: 2em;\n  border: 0.05em solid #002448;\n  margin-bottom: 0.5em;\n  color: #002448;\n  background: transparent;\n\n  &:focus, &:hover {\n    background: #c2ebf1;\n  }\n\n  &:active {\n    background: #ade4ec;\n  }\n\n  &:disabled {\n    color: #555555;\n    border-color: #555555;\n    background: transparent;\n    cursor: auto;\n  }\n\n  > svg {\n    margin-right: 0.5em;\n  }\n"])), exports.buttonCss);
exports.menuBaseCss = (0, react_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  max-height: 100vh;\n  background: #F0FBFC;\n  overflow: hidden;\n  color: #002448;\n  font-family: \"Mulish\", sans-serif;\n  box-shadow: 0 0 1em black;\n  transition: transform 0.5s;\n  will-change: transform;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  z-index: 995;\n"], ["\n  position: absolute;\n  top: 0;\n  max-height: 100vh;\n  background: #F0FBFC;\n  overflow: hidden;\n  color: #002448;\n  font-family: \"Mulish\", sans-serif;\n  box-shadow: 0 0 1em black;\n  transition: transform 0.5s;\n  will-change: transform;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  z-index: 995;\n"])));
exports.hide = (0, react_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  transform: scale(0);\n"], ["\n  transform: scale(0);\n"])));
exports.menuDialogCss = (0, react_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", ";\n  h2 {\n    margin: 0 0 0.5em;\n  }\n\n  p {\n    margin: 0.5em 0;\n  }\n"], ["\n  ", ";\n  h2 {\n    margin: 0 0 0.5em;\n  }\n\n  p {\n    margin: 0.5em 0;\n  }\n"])), react_components_1.dialogDefaultCss);
function addStylesheetUrl(url) {
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
}
exports.addStylesheetUrl = addStylesheetUrl;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
