"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rules_api_1 = require("@gamepark/rules-api");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
function usePlayerTime(playerId) {
    var _a, _b, _c, _d;
    var _e = (0, react_1.useState)(), result = _e[0], setResult = _e[1];
    var _f = (0, react_redux_1.useSelector)(function (state) { return ({
        options: state.options,
        player: state.players.find(function (p) { return p.id === playerId; }),
        clientTimeDelta: state.clientTimeDelta
    }); }), options = _f.options, player = _f.player, clientTimeDelta = _f.clientTimeDelta;
    var running = options && options.speed === rules_api_1.GameSpeed.RealTime && ((_a = player === null || player === void 0 ? void 0 : player.time) === null || _a === void 0 ? void 0 : _a.playing);
    (0, react_1.useEffect)(function () {
        if (running) {
            var intervalID_1 = setInterval(function () { return setResult(function (result) { return result ? result - 1000 : result; }); }, 1000);
            return function () { return clearInterval(intervalID_1); };
        }
        return;
    }, [running]);
    (0, react_1.useEffect)(function () {
        if ((options === null || options === void 0 ? void 0 : options.speed) !== rules_api_1.GameSpeed.RealTime || !(player === null || player === void 0 ? void 0 : player.time)) {
            setResult(undefined);
        }
        else if (player.time.playing) {
            setResult(player.time.availableTime + Date.parse(player.time.lastChange) - new Date().getTime() - clientTimeDelta);
        }
        else {
            setResult(player.time.availableTime);
        }
    }, [options === null || options === void 0 ? void 0 : options.speed, (_b = player === null || player === void 0 ? void 0 : player.time) === null || _b === void 0 ? void 0 : _b.playing, (_c = player === null || player === void 0 ? void 0 : player.time) === null || _c === void 0 ? void 0 : _c.lastChange, (_d = player === null || player === void 0 ? void 0 : player.time) === null || _d === void 0 ? void 0 : _d.availableTime, clientTimeDelta]);
    return result;
}
exports.default = usePlayerTime;
