"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GameMode;
(function (GameMode) {
    GameMode["TUTORIAL"] = "TUTORIAL";
    GameMode["FRIENDLY"] = "FRIENDLY";
    GameMode["COMPETITIVE"] = "COMPETITIVE";
    GameMode["TOURNAMENT"] = "TOURNAMENT";
})(GameMode || (GameMode = {}));
exports.default = GameMode;
