"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.gameStateLoaded = exports.GAME_STATE_LOADED = void 0;
exports.GAME_STATE_LOADED = 'GAME_STATE_LOADED';
function gameStateLoaded(data, serverTime) {
    var _a, _b;
    return __assign(__assign({ type: exports.GAME_STATE_LOADED }, data), { playerId: data.myPlayerId !== null ? data.myPlayerId : undefined, serverTime: serverTime, endDate: (_a = data.endDate) !== null && _a !== void 0 ? _a : undefined, tournament: (_b = data.tournament) !== null && _b !== void 0 ? _b : undefined });
}
exports.gameStateLoaded = gameStateLoaded;
