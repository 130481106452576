"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
var react_1 = require("@emotion/react");
var Header = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ css: headerStyle }, props, { children: (0, jsx_runtime_1.jsx)("h1", __assign({ css: titleStyle }, { children: children })) })));
};
exports.default = Header;
var headerPadding = 10; // em
var fontSize = 4.5; // em
var scrollLongTextKeyframe = (0, react_1.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%, 30% {\n    transform: none;\n  }\n  70%, 100% {\n    transform: translateX(calc(100vw - ", "em - 100%));\n  }\n"], ["\n  0%, 30% {\n    transform: none;\n  }\n  70%, 100% {\n    transform: translateX(calc(100vw - ", "em - 100%));\n  }\n"])), headerPadding * 2 / fontSize);
var headerStyle = (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  width: 100%;\n  height: 7em;\n  background-color: rgba(0, 0, 0, 0.5);\n  color: white;\n  text-align: center;\n  padding: 0 ", "em;\n  overflow: hidden;\n"], ["\n  position: absolute;\n  width: 100%;\n  height: 7em;\n  background-color: rgba(0, 0, 0, 0.5);\n  color: white;\n  text-align: center;\n  padding: 0 ", "em;\n  overflow: hidden;\n"])), headerPadding);
var titleStyle = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  white-space: nowrap;\n  width: fit-content;\n  min-width: 100%;\n  margin: 0.2em 0;\n  font-size: ", "em;\n  animation: 8s ", " infinite linear;\n"], ["\n  white-space: nowrap;\n  width: fit-content;\n  min-width: 100%;\n  margin: 0.2em 0;\n  font-size: ", "em;\n  animation: 8s ", " infinite linear;\n"])), fontSize, scrollLongTextKeyframe);
var templateObject_1, templateObject_2, templateObject_3;
