"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replayActions = exports.replayAction = exports.playActionWithViews = exports.playAction = void 0;
var Game_1 = require("./Game");
var index_1 = require("./index");
var RandomMove_1 = require("./RandomMove");
function playAction(game, move, playerId, id) {
    if ((0, RandomMove_1.hasRandomMove)(game)) {
        move = game.randomize(move);
    }
    var action = { id: id, playerId: playerId, move: move, consequences: [] };
    game.play(JSON.parse(JSON.stringify(move)));
    (0, Game_1.applyAutomaticMoves)(game, function (move) { return action.consequences.push(move); });
    return action;
}
exports.playAction = playAction;
function playActionWithViews(game, move, playerId, recipients, id) {
    if ((0, RandomMove_1.hasRandomMove)(game)) {
        move = game.randomize(move);
    }
    var actionWithView = {
        action: { id: id, playerId: playerId, move: move, consequences: [] },
        views: []
    };
    // Prepare action view for each player
    for (var _i = 0, recipients_1 = recipients; _i < recipients_1.length; _i++) {
        var recipient = recipients_1[_i];
        actionWithView.views.push({ recipient: recipient, action: { id: id, playerId: playerId, move: (0, index_1.getMoveView)(game, move, recipient), consequences: [] } });
    }
    // Prepare action view for spectators
    actionWithView.views.push({ action: { id: id, playerId: playerId, move: (0, index_1.getMoveView)(game, move), consequences: [] } });
    game.play(JSON.parse(JSON.stringify(move)));
    (0, Game_1.applyAutomaticMoves)(game, function (move) {
        actionWithView.action.consequences.push(move);
        for (var _i = 0, _a = actionWithView.views; _i < _a.length; _i++) {
            var view = _a[_i];
            view.action.consequences.push((0, index_1.getMoveView)(game, move, view.recipient));
        }
    });
    return actionWithView;
}
exports.playActionWithViews = playActionWithViews;
function replayAction(game, action) {
    game.play(JSON.parse(JSON.stringify(action.move)));
    action.consequences.forEach(function (move) { return game.play(JSON.parse(JSON.stringify(move))); });
}
exports.replayAction = replayAction;
function replayActions(game, actions) {
    actions.forEach(function (action) { return replayAction(game, action); });
}
exports.replayActions = replayActions;
