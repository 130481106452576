"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Dummy = /** @class */ (function () {
    function Dummy(Rules) {
        this.Rules = Rules;
    }
    Dummy.prototype.getRandomMove = function (state, playerId) {
        var moves = this.getLegalMoves(state, playerId);
        if (!moves.length)
            return Promise.resolve([]);
        return Promise.resolve([moves[Math.floor(Math.random() * moves.length)]]);
    };
    Dummy.prototype.getLegalMoves = function (state, playerId) {
        var rules = new this.Rules(state);
        if (isSequentialGame(rules)) {
            if (rules.getActivePlayer() === playerId) {
                return rules.getLegalMoves();
            }
            else {
                return [];
            }
        }
        else if (hasLegalMoves(rules)) {
            return rules.getLegalMoves(playerId);
        }
        else {
            console.error('Dummy player cannot work if getLegalMoves is not implemented!');
            return [];
        }
    };
    return Dummy;
}());
exports.default = Dummy;
function isSequentialGame(rules) {
    var game = rules;
    return typeof game.getActivePlayer === 'function' && typeof game.getLegalMoves === 'function';
}
function hasLegalMoves(rules) {
    var game = rules;
    return typeof game.getLegalMoves === 'function';
}
