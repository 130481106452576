"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_MESSAGE_AUTHOR = exports.NOTIFICATIONS = exports.PLAYERS_TIME = exports.AVATAR_INFO = void 0;
var client_1 = require("@apollo/client");
var ActionFailed_1 = require("../../Actions/ActionFailed");
var GameNotificationsReceived_1 = require("../../Actions/GameNotificationsReceived");
var index_1 = require("../../index");
var apollo_client_1 = require("./apollo-client");
exports.AVATAR_INFO = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["fragment AvatarInfo on Avataaar {accessoriesType clotheColor clotheType eyeType eyebrowType facialHairType facialHairColor graphicType hairColor mouthType skinColor topType}"], ["fragment AvatarInfo on Avataaar {accessoriesType clotheColor clotheType eyeType eyebrowType facialHairType facialHairColor graphicType hairColor mouthType skinColor topType}"])));
exports.PLAYERS_TIME = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["fragment PlayerTime on Player { time { availableTime cumulatedDownTime cumulatedPlayTime cumulatedWaitForMeTime highestDownTime highestPlayTime lastChange playing weightedWaitForMeTime } }"], ["fragment PlayerTime on Player { time { availableTime cumulatedDownTime cumulatedPlayTime cumulatedWaitForMeTime highestDownTime highestPlayTime lastChange playing weightedWaitForMeTime } }"])));
exports.NOTIFICATIONS = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["fragment Notifications on GameNotification { playerId ... on MovePlayedNotification { actionId moveView consequences } ... on MoveUndoneNotification { actionId moveUndone } }"], ["fragment Notifications on GameNotification { playerId ... on MovePlayedNotification { actionId moveView consequences } ... on MoveUndoneNotification { actionId moveUndone } }"])));
var PLAY = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["mutation PlayMoves($gameId: String!, $moves: [JSON!]!) { playMoves(gameId: $gameId, moves: $moves) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ", " ", ""], ["mutation PlayMoves($gameId: String!, $moves: [JSON!]!) { playMoves(gameId: $gameId, moves: $moves) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ", " ", ""])), exports.NOTIFICATIONS, exports.PLAYERS_TIME);
var UNDO = (0, client_1.gql)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["mutation UndoMoves($gameId: String!, $actions: [String!]!) { undoMoves(gameId: $gameId, actions: $actions) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ", " ", ""], ["mutation UndoMoves($gameId: String!, $actions: [String!]!) { undoMoves(gameId: $gameId, actions: $actions) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ", " ", ""])), exports.NOTIFICATIONS, exports.PLAYERS_TIME);
var EJECT_PLAYER = (0, client_1.gql)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["mutation EjectPlayer($gameId: String!, $playerId: JSON!) { ejectPlayer(gameId: $gameId, playerId: $playerId) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ", " ", ""], ["mutation EjectPlayer($gameId: String!, $playerId: JSON!) { ejectPlayer(gameId: $gameId, playerId: $playerId) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ", " ", ""])), exports.NOTIFICATIONS, exports.PLAYERS_TIME);
var GIVE_UP = (0, client_1.gql)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["mutation GiveUp($gameId: String!) { giveUp(gameId: $gameId) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ", " ", ""], ["mutation GiveUp($gameId: String!) { giveUp(gameId: $gameId) { notifications { ...Notifications } index players { id ...PlayerTime } date } } ", " ", ""])), exports.NOTIFICATIONS, exports.PLAYERS_TIME);
exports.GET_MESSAGE_AUTHOR = (0, client_1.gql)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  query GetMessageAuthor($id: String!) {\n    user(id: $id) {\n      id name avatar {...AvatarInfo}\n    }\n  } ", "\n"], ["\n  query GetMessageAuthor($id: String!) {\n    user(id: $id) {\n      id name avatar {...AvatarInfo}\n    }\n  } ", "\n"])), exports.AVATAR_INFO);
var GameClientAPI = /** @class */ (function () {
    function GameClientAPI(gameId) {
        this.pendingActions = [];
        this.gameId = gameId;
    }
    GameClientAPI.prototype.init = function (dispatch) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.dispatch = dispatch;
                return [2 /*return*/];
            });
        });
    };
    GameClientAPI.prototype.play = function (move) {
        var _this = this;
        if (this.pendingActions.length === 0) {
            this.pendingActions.push({ type: 'PLAY', moves: [move] });
        }
        else {
            var lastPendingAction = this.pendingActions[this.pendingActions.length - 1];
            if (lastPendingAction.type === 'PLAY') {
                lastPendingAction.moves.push(move);
            }
            else {
                this.pendingActions.push({ type: 'PLAY', moves: [move] });
            }
        }
        if (!this.ongoingAction) {
            // Wait for 50ms before sending this move: maybe more moves are coming very soon
            this.ongoingAction = new Promise(function (resolve) { return setTimeout(resolve, 50); }).then(function () { return _this.executePendingActions(); });
        }
    };
    GameClientAPI.prototype.undo = function (action) {
        if (typeof action === 'number')
            return console.error('GameClientAPI can no longer undo without an action ID');
        if (this.pendingActions.length === 0) {
            this.pendingActions.push({ type: 'UNDO', actions: [action] });
        }
        else {
            var lastPendingAction = this.pendingActions[this.pendingActions.length - 1];
            if (lastPendingAction.type === 'UNDO') {
                lastPendingAction.actions.push(action);
            }
            else {
                this.pendingActions.push({ type: 'UNDO', actions: [action] });
            }
        }
        if (!this.ongoingAction) {
            this.executePendingActions();
        }
    };
    GameClientAPI.prototype.executePendingActions = function () {
        var _this = this;
        if (this.pendingActions.length > 0) {
            var pendingAction = this.pendingActions.shift();
            if (pendingAction.type == 'PLAY') {
                this.ongoingAction = (0, apollo_client_1.getApolloClient)().mutate({
                    mutation: PLAY, variables: { gameId: this.gameId, moves: pendingAction.moves }
                }).then(function (result) { return result.data && _this.handleGameMessageResult(result.data.playMoves); });
            }
            else if (pendingAction.type === 'UNDO') {
                this.ongoingAction = (0, apollo_client_1.getApolloClient)().mutate({
                    mutation: UNDO, variables: { gameId: this.gameId, actions: pendingAction.actions }
                }).then(function (result) { return result.data && _this.handleGameMessageResult(result.data.undoMoves); });
            }
            if (this.ongoingAction) {
                this.ongoingAction = this.ongoingAction.catch(function (error) { return _this.onError(error); }).finally(function () { return _this.executePendingActions(); });
            }
        }
        else {
            delete this.ongoingAction;
        }
    };
    GameClientAPI.prototype.handleGameMessageResult = function (_a) {
        var date = _a.date, index = _a.index, notifications = _a.notifications, players = _a.players;
        this.dispatch((0, GameNotificationsReceived_1.gameNotificationsReceived)(notifications, index, players, Date.parse(date)));
    };
    GameClientAPI.prototype.eject = function (playerId) {
        var _this = this;
        (0, apollo_client_1.getApolloClient)().mutate({ mutation: EJECT_PLAYER, variables: { gameId: this.gameId, playerId: playerId } })
            .then(function (result) { return result.data && _this.handleGameMessageResult(result.data.ejectPlayer); });
    };
    GameClientAPI.prototype.giveUp = function () {
        var _this = this;
        (0, apollo_client_1.getApolloClient)().mutate({ mutation: GIVE_UP, variables: { gameId: this.gameId } })
            .then(function (result) { return result.data && _this.handleGameMessageResult(result.data.giveUp); });
    };
    GameClientAPI.prototype.onError = function (_a) {
        var _this = this;
        var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError;
        if (graphQLErrors) {
            graphQLErrors.map(function (_a) {
                var message = _a.message;
                return _this.dispatch((0, ActionFailed_1.actionFailed)(message));
            });
        }
        if (networkError) {
            console.error(networkError);
            this.dispatch((0, ActionFailed_1.actionFailed)(index_1.Failure.NETWORK));
        }
    };
    GameClientAPI.prototype.playTutorialMoves = function () {
        console.error('Tutorial should only be played with local API');
    };
    return GameClientAPI;
}());
exports.default = GameClientAPI;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
