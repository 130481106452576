"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
var react_1 = require("react");
function Picture(_a) {
    var innerRef = _a.innerRef, picture = _a.picture, alt = _a.alt, props = __rest(_a, ["innerRef", "picture", "alt"]);
    var srcTest = (0, react_1.useMemo)(function () { return props.src ? props.src.match(/(.*)\.(jpg|png)$/) : null; }, [props.src]);
    return ((0, jsx_runtime_1.jsxs)("picture", __assign({}, picture, { children: [srcTest && (0, jsx_runtime_1.jsx)("source", { srcSet: srcTest[1] + '.webp', type: "image/webp" }), (0, jsx_runtime_1.jsx)("img", __assign({ ref: innerRef, draggable: false, alt: alt }, props))] })));
}
exports.default = (0, react_1.forwardRef)(function (props, ref) { return (0, jsx_runtime_1.jsx)(Picture, __assign({ innerRef: ref }, props)); });
