"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
var react_1 = require("@emotion/react");
var react_2 = require("react");
var HandItem = function (_a) {
    var children = _a.children, _b = _a.rotationOrigin, rotationOrigin = _b === void 0 ? 10 : _b, angle = _a.angle, _c = _a.sizeRatio, sizeRatio = _c === void 0 ? 2.5 / 3.5 : _c, projection = _a.projection, animation = _a.animation, hoverStyle = _a.hoverStyle, itemSelector = _a.itemSelector, innerRef = _a.innerRef, props = __rest(_a, ["children", "rotationOrigin", "angle", "sizeRatio", "projection", "animation", "hoverStyle", "itemSelector", "innerRef"]);
    var projectionStyle = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    transform: translateX(", "%);\n  "], ["\n    transform: translateX(", "%);\n  "])), Math.sin(angle * Math.PI / 180) * (rotationOrigin * 100 - 50) / sizeRatio);
    var transformation = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    &:before, > * {\n      transform-origin: center ", "%;\n      transform-style: preserve-3d;\n      ", ";\n      ", ";\n      will-change: transform;\n    }\n  "], ["\n    &:before, > * {\n      transform-origin: center ", "%;\n      transform-style: preserve-3d;\n      ", ";\n      ", ";\n      will-change: transform;\n    }\n  "])), rotationOrigin * 100, projection ? projectionStyle : (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["transform: rotate(", "deg)"], ["transform: rotate(", "deg)"])), angle), animation && getAnimationStyle(animation));
    return ((0, jsx_runtime_1.jsx)("div", __assign({ ref: innerRef, css: [style, transformation, hoverStyle && !animation && getHoverStyle(hoverStyle, projectionStyle, itemSelector)] }, props, { children: (0, jsx_runtime_1.jsx)("div", __assign({ css: childStyle }, { children: children })) })));
};
var style = (0, react_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  pointer-events: none;\n  position: absolute;\n\n  &:before {\n    content: '';\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 2;\n    pointer-events: auto;\n  }\n"], ["\n  pointer-events: none;\n  position: absolute;\n\n  &:before {\n    content: '';\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 2;\n    pointer-events: auto;\n  }\n"])));
var childStyle = (0, react_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  pointer-events: auto;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  pointer-events: auto;\n"])));
var getAnimationStyle = function (_a) {
    var seconds = _a.seconds, _b = _a.delay, delay = _b === void 0 ? 0 : _b, _c = _a.timingFunction, timingFunction = _c === void 0 ? 'ease-in-out' : _c, fromNeutralPosition = _a.fromNeutralPosition, toNeutralPosition = _a.toNeutralPosition;
    if (fromNeutralPosition) {
        return (0, react_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["animation: ", " ", "s ", " forwards"], ["animation: ", " ", "s ", " forwards"])), fromTransformNone(delay / (delay + seconds)), delay + seconds, timingFunction);
    }
    else if (toNeutralPosition) {
        return (0, react_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["animation: ", " ", "s ", " forwards"], ["animation: ", " ", "s ", " forwards"])), toTransformNone, delay + seconds, timingFunction);
    }
    else {
        return (0, react_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["transition: transform ", "s ", "s ", ";"], ["transition: transform ", "s ", "s ", ";"])), seconds, delay, timingFunction);
    }
};
var fromTransformNone = function (delay) { return (0, react_1.keyframes)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  from {\n    transform: none\n  }\n  ", "\n"], ["\n  from {\n    transform: none\n  }\n  ", "\n"])), delay && "".concat(delay * 100, "% {transform: none}")); };
var toTransformNone = (0, react_1.keyframes)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  to {\n    transform: none\n  }\n"], ["\n  to {\n    transform: none\n  }\n"])));
var getHoverStyle = function (hoverStyle, projection, itemSelector) {
    if (itemSelector === void 0) { itemSelector = '> * > *'; }
    return (0, react_1.css)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  @media (hover) {\n    &:hover {\n      > * {\n        ", ";\n        z-index: 1;\n      }\n\n      ", " {\n        ", ";\n      }\n    }\n  }\n"], ["\n  @media (hover) {\n    &:hover {\n      > * {\n        ", ";\n        z-index: 1;\n      }\n\n      ", " {\n        ", ";\n      }\n    }\n  }\n"])), projection, itemSelector, hoverStyle);
};
exports.default = (0, react_2.forwardRef)(function (props, ref) { return (0, jsx_runtime_1.jsx)(HandItem, __assign({ innerRef: ref }, props)); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
