"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMovePlayedNotification = exports.movePlayedNotification = void 0;
function movePlayedNotification(action) {
    var _a;
    return { actionId: (_a = action.id) !== null && _a !== void 0 ? _a : null, playerId: action.playerId, moveView: action.move, consequences: action.consequences };
}
exports.movePlayedNotification = movePlayedNotification;
function isMovePlayedNotification(notification) {
    return notification.moveView != undefined;
}
exports.isMovePlayedNotification = isMovePlayedNotification;
