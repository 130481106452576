"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMoveUndoneNotification = exports.moveUndoneNotification = void 0;
function moveUndoneNotification(action, moveUndone) {
    var _a;
    return { playerId: action.playerId, actionId: (_a = action.id) !== null && _a !== void 0 ? _a : null, moveUndone: moveUndone };
}
exports.moveUndoneNotification = moveUndoneNotification;
function isMoveUndoneNotification(notification) {
    return notification.moveUndone != undefined;
}
exports.isMoveUndoneNotification = isMoveUndoneNotification;
