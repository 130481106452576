"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMoveView = exports.getGameView = exports.hasSecretInformation = exports.hasIncompleteInformation = void 0;
function hasIncompleteInformation(rules) {
    var game = rules;
    return typeof game.getView === 'function' && typeof game.getMoveView === 'function';
}
exports.hasIncompleteInformation = hasIncompleteInformation;
function hasSecretInformation(rules) {
    var game = rules;
    return typeof game.getPlayerView === 'function';
}
exports.hasSecretInformation = hasSecretInformation;
function getGameView(game, playerId) {
    if (hasSecretInformation(game) && playerId !== undefined) {
        return game.getPlayerView(playerId);
    }
    else {
        return game.getView();
    }
}
exports.getGameView = getGameView;
function getMoveView(game, move, playerId) {
    if (hasSecretInformation(game) && playerId !== undefined && game.getPlayerMoveView) {
        return JSON.parse(JSON.stringify(game.getPlayerMoveView(move, playerId)));
    }
    else {
        return JSON.parse(JSON.stringify(game.getMoveView(move)));
    }
}
exports.getMoveView = getMoveView;
