"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var GameContext_1 = require("../GameProvider/GameContext");
function useSound(src) {
    var context = (0, react_1.useContext)(GameContext_1.gameContext);
    var audio = (0, react_1.useState)(new Audio(src))[0];
    audio.muted = (0, react_redux_1.useSelector)(function (state) { return state.soundsMuted; });
    (0, react_1.useEffect)(function () {
        if (context) {
            context.hasSounds = true;
        }
    }, [context]);
    return audio;
}
exports.default = useSound;
