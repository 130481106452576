"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameSpeed = exports.hasTimeLimit = void 0;
function hasTimeLimit(rules) {
    return typeof rules.giveTime === 'function';
}
exports.hasTimeLimit = hasTimeLimit;
var GameSpeed;
(function (GameSpeed) {
    GameSpeed["Disabled"] = "Disabled";
    GameSpeed["RealTime"] = "RealTime";
})(GameSpeed = exports.GameSpeed || (exports.GameSpeed = {}));
