"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function consoleTools(gameServerProxy, store) {
    return {
        get state() {
            return gameServerProxy.getLocalStore().state;
        },
        get store() {
            return store.getState();
        },
        get moves() {
            return gameServerProxy.getLocalStore().actions;
        },
        new: function (options) {
            gameServerProxy.newGame(options);
            window.location.reload();
        },
        undo: function (quantity) {
            if (quantity === void 0) { quantity = 1; }
            gameServerProxy.undo(quantity);
        },
        monkeyOpponents: function (start) {
            if (start === void 0) { start = true; }
            if (start) {
                gameServerProxy.startMonkeyOpponents();
            }
            else {
                gameServerProxy.stopMonkeyOpponents();
            }
        },
        setPlayerName: function (name, playerId) {
            gameServerProxy.setPlayerName(name, playerId);
            window.location.reload();
        },
        setAnimationsSpeed: function (speed) {
            if (speed === void 0) { speed = 1; }
            gameServerProxy.setAnimationsSpeed(speed);
        },
        tutorial: function () {
            gameServerProxy.newTutorial();
            window.location.reload();
        }
    };
}
exports.default = consoleTools;
