"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rules_api_1 = require("@gamepark/rules-api");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var MoveUndone_1 = require("../Actions/MoveUndone");
var GameContext_1 = require("../GameProvider/GameContext");
var ArrayUtil_1 = require("../util/ArrayUtil");
function useUndo() {
    var _a;
    var actions = (0, react_redux_1.useSelector)(function (state) { return state.actions; });
    var setup = (0, react_redux_1.useSelector)(function (state) { return state.setup; });
    var playerId = (0, react_redux_1.useSelector)(function (state) { return state.playerId; });
    var dispatch = (0, react_redux_1.useDispatch)();
    var RulesView = (_a = (0, react_1.useContext)(GameContext_1.gameContext)) === null || _a === void 0 ? void 0 : _a.RulesView;
    if (!RulesView)
        throw new Error('Cannot useUndo outside a GameProvider');
    var undo = (0, react_1.useCallback)(function (arg, options) {
        if (typeof arg === 'string') {
            dispatch((0, MoveUndone_1.moveUndone)(arg, options === null || options === void 0 ? void 0 : options.delayed));
        }
        else {
            if (!actions)
                return console.error('Cannot undo before actions history is loaded');
            var action = (0, ArrayUtil_1.findLastItem)(actions, function (action) { return action.playerId === playerId && !action.cancelled && (typeof arg !== 'function' || arg(action.move)); });
            if (!action)
                return console.error('This action does not exist, it cannot be undone');
            var delayed = typeof arg !== 'function' ? arg === null || arg === void 0 ? void 0 : arg.delayed : options === null || options === void 0 ? void 0 : options.delayed;
            if (action.id) {
                dispatch((0, MoveUndone_1.moveUndone)(action.id, delayed));
            }
        }
    }, [actions, playerId, dispatch]);
    var canUndo = (0, react_1.useCallback)(function (movePredicate) {
        if (!setup || !actions)
            return false;
        var index = (0, ArrayUtil_1.findLastIndex)(actions, function (action) { return action.playerId === playerId && !action.cancelled && (!movePredicate || movePredicate(action.move)); });
        if (index === -1)
            return false;
        var consecutiveActions = actions.slice(index + 1).filter(function (action) { return !action.cancelled; });
        var game = new RulesView(JSON.parse(JSON.stringify(setup)));
        if (!(0, rules_api_1.hasUndo)(game))
            return false;
        (0, rules_api_1.replayActions)(game, actions.filter(function (action) { return !action.delayed && !action.cancelled; }));
        return game.canUndo(actions[index], consecutiveActions);
    }, [setup, actions, playerId, RulesView]);
    return [undo, canUndo];
}
exports.default = useUndo;
