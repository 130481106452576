"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
var client_1 = require("@apollo/client");
var react_1 = require("@emotion/react");
var free_regular_svg_icons_1 = require("@fortawesome/free-regular-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_2 = require("react");
var react_i18next_1 = require("react-i18next");
var Menu_1 = require("../Menu");
var SEND_MESSAGE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["mutation SendMessage($channel: String!, $text: String!) { sendMessage(channel: $channel, text: $text) { date } }"], ["mutation SendMessage($channel: String!, $text: String!) { sendMessage(channel: $channel, text: $text) { date } }"])));
function ChatTextInput(_a) {
    var channel = _a.channel, inputRef = _a.inputRef;
    var t = (0, react_i18next_1.useTranslation)().t;
    var sendMessage = (0, client_1.useMutation)(SEND_MESSAGE)[0];
    var _b = (0, react_2.useState)(''), text = _b[0], setText = _b[1];
    var onSubmit = function (event) {
        event.preventDefault();
        sendMessage({ variables: { channel: channel, text: text } }).catch(console.error);
        setText('');
    };
    return ((0, jsx_runtime_1.jsxs)("form", __assign({ css: messageBar, onSubmit: onSubmit }, { children: [(0, jsx_runtime_1.jsx)("input", { ref: inputRef, type: "text", placeholder: t('Type a message'), css: textInputCss, value: text, onChange: function (event) { return setText(event.target.value); } }), (0, jsx_runtime_1.jsx)("button", __assign({ "aria-label": t('Send'), title: t('Send'), css: [Menu_1.buttonCss, sendButtonStyle] }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_regular_svg_icons_1.faPaperPlane }) }))] })));
}
exports.default = ChatTextInput;
var messageBar = (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0.5em;\n  background: #28B8CE;\n  display: flex;\n"], ["\n  width: 100%;\n  padding: 0.5em;\n  background: #28B8CE;\n  display: flex;\n"])));
var textInputCss = (0, react_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-grow: 1;\n  border: none;\n  border-radius: 1em;\n  padding: 0.2em 0.5em;\n\n  &:focus-visible {\n    outline: none;\n  }\n"], ["\n  flex-grow: 1;\n  border: none;\n  border-radius: 1em;\n  padding: 0.2em 0.5em;\n\n  &:focus-visible {\n    outline: none;\n  }\n"])));
var sendButtonStyle = (0, react_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: none;\n  color: white;\n"], ["\n  background: none;\n  color: white;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
