"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var shallowequal_1 = require("@react-dnd/shallowequal");
var react_1 = require("react");
var react_dnd_1 = require("react-dnd");
function useEfficientDragLayer(collect) {
    var requestID = (0, react_1.useRef)();
    var collectCallback = (0, react_1.useCallback)(function (monitor) { return requestID.current === undefined ? { data: collect(monitor) } : undefined; }, [collect]);
    var collected = (0, react_dnd_1.useDragLayer)(collectCallback);
    var result = (0, react_1.useRef)(collected === null || collected === void 0 ? void 0 : collected.data);
    if (collected && !(0, shallowequal_1.shallowEqual)(result.current, collected.data)) {
        result.current = collected.data;
        requestID.current = requestAnimationFrame(function () { return requestID.current = undefined; });
    }
    return result.current;
}
exports.default = useEfficientDragLayer;
