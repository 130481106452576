"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rules_api_1 = require("@gamepark/rules-api");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var PlayerTime_1 = require("../PlayerTime/PlayerTime");
/**
 * Returns true if I wait an opponent with negative time
 */
function useOpponentWithMaxTime(time) {
    var options = (0, react_redux_1.useSelector)(function (state) { return state.options; });
    var playerId = (0, react_redux_1.useSelector)(function (state) { return state.playerId; });
    var players = (0, react_redux_1.useSelector)(function (state) { return state.players; });
    var clientTimeDelta = (0, react_redux_1.useSelector)(function (state) { return state.clientTimeDelta; });
    var player = players.find(function (p) { return p.id === playerId; });
    var awaitedPlayers = players.filter(function (p) { var _a; return (_a = p.time) === null || _a === void 0 ? void 0 : _a.playing; }).sort(function (a, b) { return (0, PlayerTime_1.getPlayerTimeout)(a) - (0, PlayerTime_1.getPlayerTimeout)(b); });
    var opponent = awaitedPlayers.find(function (p) { return p.id !== playerId; });
    var _a = (0, react_1.useState)(opponent), result = _a[0], setResult = _a[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (!options || options.speed === rules_api_1.GameSpeed.Disabled || ((_a = player === null || player === void 0 ? void 0 : player.time) === null || _a === void 0 ? void 0 : _a.playing) || !opponent) {
            setResult(undefined);
        }
        else {
            var remainingTime = (0, PlayerTime_1.getPlayerTimeout)(opponent) - new Date().getTime() - clientTimeDelta + (time !== null && time !== void 0 ? time : options.maxExceedTime);
            if (remainingTime > 0) {
                setResult(undefined);
                var timeout_1 = setTimeout(function () { return setResult(opponent); }, remainingTime);
                return function () { return clearTimeout(timeout_1); };
            }
            else {
                setResult(opponent);
            }
        }
        return;
    }, [options, player, opponent, clientTimeDelta]);
    return result;
}
exports.default = useOpponentWithMaxTime;
