"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var client_1 = require("@apollo/client");
var use_pusher_1 = require("@harelpls/use-pusher");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var GameNotificationsReceived_1 = require("../../Actions/GameNotificationsReceived");
var GameOver_1 = require("../../Actions/GameOver");
var GameClientAPI_1 = require("./GameClientAPI");
var GET_NOTIFICATIONS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GameNotifications($gameId: String!, $start: Int) {\n    time\n    gameNotifications(gameId: $gameId, start: $start) { ...Notifications }\n    game(id: $gameId) {\n      players { id ...PlayerTime }\n    }\n  } ", " ", "\n"], ["\n  query GameNotifications($gameId: String!, $start: Int) {\n    time\n    gameNotifications(gameId: $gameId, start: $start) { ...Notifications }\n    game(id: $gameId) {\n      players { id ...PlayerTime }\n    }\n  } ", " ", "\n"])), GameClientAPI_1.NOTIFICATIONS, GameClientAPI_1.PLAYERS_TIME);
function GameNotificationsListener(_a) {
    var _this = this;
    var gameId = _a.gameId, channelName = _a.channelName;
    var channel = (0, use_pusher_1.useChannel)(channelName);
    var client = (0, client_1.useApolloClient)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var notification = (0, react_1.useRef)(0);
    (0, use_pusher_1.useEvent)(channel, 'pusher:subscription_error', function (error) { return console.error('pusher subscription failed', error); });
    (0, use_pusher_1.useEvent)(channel, 'pusher:subscription_succeeded', function () { return __awaiter(_this, void 0, void 0, function () {
        var start, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    start = notification.current;
                    return [4 /*yield*/, client.query({
                            query: GET_NOTIFICATIONS,
                            variables: { gameId: gameId, start: start }
                        })];
                case 1:
                    data = (_a.sent()).data;
                    if (!data)
                        return [2 /*return*/, console.error('Failed to collect game data')];
                    dispatch((0, GameNotificationsReceived_1.gameNotificationsReceived)(data.gameNotifications, start, data.game.players, data.time));
                    if (data.gameNotifications.length > 0) {
                        notification.current = Math.max(notification.current, start + data.gameNotifications.length);
                    }
                    return [2 /*return*/];
            }
        });
    }); });
    (0, use_pusher_1.useEvent)(channel, 'moves-played', function (message) {
        if (!message)
            return;
        var index = message.index, notifications = message.notifications, players = message.players, date = message.date;
        dispatch((0, GameNotificationsReceived_1.gameNotificationsReceived)(notifications, index, players, Date.parse(date)));
        notification.current = Math.max(notification.current, index + notifications.length);
    });
    (0, use_pusher_1.useEvent)(channel, 'game-over', function (players) {
        if (players) {
            dispatch((0, GameOver_1.gameOver)(players));
        }
    });
    return null;
}
exports.default = GameNotificationsListener;
var templateObject_1;
