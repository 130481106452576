"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initDatadogLogs = void 0;
var browser_logs_1 = require("@datadog/browser-logs");
function initDatadogLogs() {
    var _a;
    if (process.env.NODE_ENV === 'production') {
        browser_logs_1.datadogLogs.init({
            clientToken: (_a = process.env.REACT_APP_DATADOG_CLIENT_TOKEN) !== null && _a !== void 0 ? _a : 'pubdb04a43151132f11ed7347e785e3902a',
            site: 'datadoghq.eu'
        });
        browser_logs_1.datadogLogs.logger.setLevel(process.env.REACT_APP_LOGGER_LEVEL || browser_logs_1.StatusType.error);
        // The following code may be removed later, see: https://github.com/DataDog/browser-sdk/issues/400
        var buildMessage_1 = function (message) {
            var optionalParams = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                optionalParams[_i - 1] = arguments[_i];
            }
            return __spreadArray([message], optionalParams, true).map(function (param) { return JSON.stringify(param, undefined, 2); }).join(' ');
        };
        console.log = function (message) {
            var optionalParams = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                optionalParams[_i - 1] = arguments[_i];
            }
            return browser_logs_1.datadogLogs.logger.log(buildMessage_1(message, optionalParams), { origin: 'console' });
        };
        console.debug = function (message) {
            var optionalParams = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                optionalParams[_i - 1] = arguments[_i];
            }
            return browser_logs_1.datadogLogs.logger.debug(buildMessage_1(message, optionalParams), { origin: 'console' });
        };
        console.info = function (message) {
            var optionalParams = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                optionalParams[_i - 1] = arguments[_i];
            }
            return browser_logs_1.datadogLogs.logger.info(buildMessage_1(message, optionalParams), { origin: 'console' });
        };
        console.warn = function (message) {
            var optionalParams = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                optionalParams[_i - 1] = arguments[_i];
            }
            return browser_logs_1.datadogLogs.logger.warn(buildMessage_1(message, optionalParams), { origin: 'console' });
        };
    }
}
exports.initDatadogLogs = initDatadogLogs;
