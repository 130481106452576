"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var THEME_STORAGE = 'light-theme';
function useContrastTheme(defaultLight) {
    if (defaultLight === void 0) { defaultLight = false; }
    var _a = (0, react_1.useState)(function () { var _a; return (_a = getStoredTheme()) !== null && _a !== void 0 ? _a : defaultLight; }), light = _a[0], setLight = _a[1];
    var toggleContrast = (0, react_1.useCallback)(function () { return setLight(function (light) {
        localStorage.setItem(THEME_STORAGE, JSON.stringify(!light));
        return !light;
    }); }, []);
    return { light: light, toggleContrast: toggleContrast };
}
exports.default = useContrastTheme;
function getStoredTheme() {
    var item = localStorage.getItem(THEME_STORAGE);
    if (!item)
        return;
    var theme = JSON.parse(item);
    if (typeof theme !== 'boolean')
        return;
    return theme;
}
