"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomizeMoves = exports.hasRandomMove = void 0;
function hasRandomMove(game) {
    return typeof game.randomize === 'function';
}
exports.hasRandomMove = hasRandomMove;
function randomizeMoves(game, moves) {
    return hasRandomMove(game) ? moves.map(function (move) { return game.randomize(move); }) : moves;
}
exports.randomizeMoves = randomizeMoves;
