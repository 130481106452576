"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePlayersTime = void 0;
function updatePlayersTime(players, now) {
    var activePlayers = players.filter(function (player) { return player.time.playing; });
    if (activePlayers.length) {
        var durationSinceLastChange = players.reduce(function (minDuration, player) { return Math.min(now - Date.parse(player.time.lastChange), minDuration); }, Infinity);
        var cumulatedDownTime_1 = durationSinceLastChange * (players.length - activePlayers.length);
        var weightedDownTime_1 = durationSinceLastChange * (players.length - activePlayers.length) / activePlayers.length;
        players.forEach(function (player) {
            var durationSinceLastPlayerChange = now - Date.parse(player.time.lastChange);
            if (player.time.playing) {
                player.time.availableTime -= durationSinceLastPlayerChange;
                player.time.cumulatedPlayTime += durationSinceLastPlayerChange;
                player.time.highestPlayTime = Math.max(player.time.highestPlayTime, durationSinceLastPlayerChange);
                player.time.cumulatedWaitForMeTime += cumulatedDownTime_1;
                player.time.weightedWaitForMeTime += weightedDownTime_1;
            }
            else {
                player.time.cumulatedDownTime += durationSinceLastPlayerChange;
                player.time.highestDownTime = Math.max(player.time.highestDownTime, durationSinceLastPlayerChange);
            }
        });
    }
}
exports.updatePlayersTime = updatePlayersTime;
