"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_redux_1 = require("react-redux");
function useAnimations(predicate) {
    var actions = (0, react_redux_1.useSelector)(function (state) { return state.actions || []; });
    return actions.filter(function (action) { return action.animation !== undefined; })
        .map(function (action) { return ({
        move: action.animation.consequence === undefined ? action.move : action.consequences[action.animation.consequence],
        duration: action.animation.duration,
        action: action
    }); })
        .filter(function (animation) { return !predicate || predicate(animation); });
}
exports.default = useAnimations;
