"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
var react_1 = require("@emotion/react");
var react_2 = require("react");
var MenuStyles_1 = require("./MenuStyles");
function MenuPopButton(_a) {
    var children = _a.children, pop = _a.pop, _b = _a.popPosition, popPosition = _b === void 0 ? 1 : _b, props = __rest(_a, ["children", "pop", "popPosition"]);
    var _c = (0, react_2.useState)(), popAfterCreation = _c[0], setPopAfterCreation = _c[1];
    (0, react_2.useEffect)(function () {
        if (popAfterCreation === undefined) {
            setTimeout(function () { return setPopAfterCreation(pop); }, 100);
        }
        else {
            setPopAfterCreation(pop);
        }
    }, [pop]);
    return ((0, jsx_runtime_1.jsx)("button", __assign({ css: [style, popAfterCreation && popStyle(popPosition)] }, props, { children: children })));
}
exports.default = MenuPopButton;
var style = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  height: 2.25em;\n  width: 2.5em;\n  padding: 0 0.25em 0 0;\n  transition: transform 0.3s;\n  will-change: transform;\n"], ["\n  ", ";\n  height: 2.25em;\n  width: 2.5em;\n  padding: 0 0.25em 0 0;\n  transition: transform 0.3s;\n  will-change: transform;\n"])), MenuStyles_1.menuFloatingButtonCss);
var popStyle = function (position) { return (0, react_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  transform: translateX(", "em);\n"], ["\n  transform: translateX(", "em);\n"])), -2.25 * position); };
var templateObject_1, templateObject_2;
